body {
	width: 500px;
	margin: 0 auto !important;
	max-width: 500px;
    /* background: image-set('https://1759398355.rsc.cdn77.org/assets/images/BGBody.webp'); */
    /* background-size: 100%; */
   
}
#root{
    background: #fff;
    overflow: hidden;
}
.errorCont{
    padding: 0 20px;
}
.errorMain {
    background: #1bdbe0;
    color: #fff;
    text-align: center;
    padding: 40px 12px;
}
.errorMain h1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
    margin: 0;
}
.errorMain h2 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
}
.errorMain h3 {
    font-size: 1.75rem;
    font-weight: 300!important;
    margin: 10px 0 0 0;
}
ins.adsbygoogle[data-ad-status='unfilled'] {
    display: none !important;
}
.wrapper_in:has([data-ad-status='unfilled']){
    height: auto !important;
}
.wrapper_in:has([data-ad-status='filled']){
    height: 300px !important;
}
#lastRowpadd {

    margin: 0 5px;

}
@media screen and (min-width: 768px) {
    body {
        background: url(https://1759398355.rsc.cdn77.org/assets/images/BGBody.webp);
        background-size: 100%;
    }
}
@media screen and (min-width:0) and (max-width:767px){
footer p{
    padding: 0 5px;
}
body {
    width: 100%;
    max-width: 100%;
}
}
ins {
    min-width: 250px; 
    max-width: 490px;
 }
/* .wrapper_in:has([data-ad-status='filled']){
    height: 300px !important;
} */
/* .wrapper_in:has(iframe[data-load-complete="true"]){
    height: 300px !important;
} */
/* .adsbygoogle{
    min-width: 250px;
} */
/* ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
    min-width: 250px;
} */
 /* Bootstarap css start */
*,
*::before,
*::after {
  box-sizing: border-box;
}
center{
    width: 100%;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)* .5);
    /* padding-left: 0.75rem; */
    /* padding-right: 0.75rem; */
    padding-right: calc(var(--bs-gutter-x)* .5);
    width: 100%;
}
.container{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)* .5);
    /* padding-left: 0.75rem; */
    /* padding-right: 0.75rem; */
    padding-right: calc(var(--bs-gutter-x)* .5);
    width: 100%;
}
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--bs-gutter-x)* -.5);
    margin-right: calc(var(--bs-gutter-x)* -.5);
    margin-top: calc(var(--bs-gutter-y)* -1);
}
.row>* {
    /* flex-shrink: 0; */
    /* margin-top: var(--bs-gutter-y); */
    /* max-width: 100%; */
    padding-left: calc(var(--bs-gutter-x)* .5);
    padding-right: calc(var(--bs-gutter-x)* .5);
    width: 100%;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
h3 {
    line-height: 1.2;
    margin-bottom: .5rem;
    margin-top: 0;
}
.pt-2{
    padding-top: .5rem !important;
}
.mt-2{
    margin-top: .5rem !important;
}
.py-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important;
}
.py-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important;
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}
.categoryTitle{
    margin-top: 0;
}
@media (min-width: 576px) {
    .container, .container-sm {
        max-width: 540px;
    }
    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
    }
}
@media (min-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 720px;
    }
    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .col-md-6 {
        flex: 0 0 auto;
        width: 50%;
    }
}
@media (min-width: 992px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 960px;
    }
}